/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 23, 2021 */

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/AvenirLTStd-Black.woff2') format('woff2'),
    url('../fonts/AvenirLTStd-Black.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/AvenirLTStd-Roman.woff2') format('woff2'),
    url('../fonts/AvenirLTStd-Roman.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/AvenirLTStd-Book.woff2') format('woff2'),
    url('../fonts/AvenirLTStd-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway-Medium.woff2') format('woff2'),
    url('../fonts/Raleway-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway-Regular.woff2') format('woff2'),
    url('../fonts/Raleway-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway-SemiBold.woff2') format('woff2'),
    url('../fonts/Raleway-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway-Bold.woff2') format('woff2'),
    url('../fonts/Raleway-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
    url('../fonts/roboto-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto-medium-webfont.woff2') format('woff2'),
    url('../fonts/roboto-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
    url('../fonts/roboto-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
