.o-loading {
  .o-loading-progress {
    width: 280px;
  }
}

.content {
  padding: 15px 0;
}

.register {
  padding: 30px 0;
}

.active-store {
  text-align: center;
  background-color: #f3f3f3;
  padding: 5px 15px;
  justify-content: center !important;
}

.search-result {
  width: 100%;
}

$mwidth: 100vw;

.offcanvas {
  width: $mwidth;
  &.left {
    left: -$mwidth;
    &.open {
      left: auto;
      right: 0;
    }
  }
  &.right {
    right: -$mwidth;
    &.open {
      left: 0;
      right: auto;
    }
  }
  &.menu-mobile {
    width: 80vw;
    &.left {
      &.open {
        left: 0;
      }
    }
  }
}
