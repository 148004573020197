@import 'variables';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $content;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-0 {
  padding: 0;
}

.navbar-full-between {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .link {
    color: $primary;
    font-weight: bold;
    &:hover {
      text-decoration: none;
    }
  }
  .nav-separator {
    height: 35px;
    width: 1px;
    border: 1px solid $primary;
  }
}

.navbar {
  background-color: $white;
  color: $primary;
  padding: 1.4rem 1rem;
  box-shadow: $box-shadow;
  z-index: 101;
  .nav-link {
    color: $primary;
    font-weight: 400;
    font-size: 16px;
    position: relative;
    &:hover,
    &.active {
      font-weight: 900;
      &::before {
        visibility: visible;
      }
    }
    &.nav-link-grouped {
      &:hover,
      &.active {
        &::before {
          visibility: hidden;
        }
      }
    }
    &.nav-no-before {
      &:hover,
      &.active {
        &::before {
          visibility: hidden;
        }
      }
    }
  }
  .nav-grouped {
    display: none;
  }
  .navbar-toggler {
    border-color: $primary;
  }
}

.navbar-toggle {
  position: relative;
  padding: 0;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-right: -8px;
  &:focus,
  &:hover {
    background-color: transparent;
    outline: 0;
  }
  .icon-bar {
    background-color: $primary;
    height: 3px;
    width: 30px;
    display: block;
    margin: 5px 0;

    border-radius: 3px;
    transition: all 0.2s;
  }
  .top-bar {
    transform: translate(6px) rotate(45deg);
    transform-origin: 8% 15%;
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    transform: translate(8px) rotate(-45deg);
    transform-origin: 10% 190%;
    margin-bottom: 0;
  }
}
.navbar-toggle.collapsed {
  .icon-bar {
    margin-left: 0;
  }
  .top-bar {
    transform: rotate(0);
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    transform: rotate(0);
  }
}

.footer {
  padding: 30px 0;
  background-color: $white;
  .footer-title {
    font-weight: bold;
    font-size: 19px;
    margin-bottom: 10px;
  }
  .footer-list {
    padding-left: 0;
    margin: 0;
    li {
      list-style: none;
      margin-bottom: 10px;
      a {
        color: $black;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.register {
  padding: 4rem;
}

.content {
  padding: 4rem 0;
}

.clear-ul {
  padding-left: 0;
  list-style: none;
}

.modal-title {
  font-size: 1rem;
}

.modal-close {
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.product-list {
  .discount-badge {
    background-color: $primary;
    padding: 2px 12px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: $white;
    font-weight: bold;
    font-size: 13px;
    &.absolute {
      top: 15px;
      left: 0;
      position: absolute;
    }
    &.full {
      border-radius: 5px;
    }
  }
  .name {
    height: 43px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.spinner {
  &.sk-flow {
    align-items: center;
  }
  &.white {
    --sk-color: white;
  }
}

$vs-checkbox-radio-border-width: 2px;
$vs-checkbox-radio-border-color: #999;
$vs-checkbox-box: 16px;
$vs-checkbox-box-sm: 11px;
$vs-checkbox-box-lg: 20px;

// .checkbox-wrapper {
.vx-checkbox-con {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // margin-bottom: 2px;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 200;
    cursor: pointer;
    top: 0;
    &:checked {
      ~ .vx-checkbox {
        transform: rotate(0deg);
        border-color: $primary;
        .vx-checkbox--check {
          transform: translate(0);
          background-color: $primary;
          .vx-icon {
            transform: translate(0);
            line-height: 1.2;
            opacity: 1;
          }
        }
        &.vx-checkbox-sm {
          .vx-checkbox--check .vx-icon {
            font-size: 0.75rem;
          }
        }
        &.vx-checkbox-lg {
          .vx-checkbox--check .vx-icon {
            font-size: 1.2rem;
          }
        }
      }
    }
    &:active:checked + .vx-checkbox {
      .vx-checkbox--check {
        transform: translate(3px);
        background-color: $primary;
        .vx-icon {
          opacity: 1;
          transform: translate(6px);
        }
      }
    }
    &:disabled {
      cursor: default;
      pointer-events: none;
      + .vx-checkbox {
        cursor: default;
        opacity: 0.5;
      }
    }
  }
  .vx-checkbox {
    cursor: pointer;
    position: relative;
    width: $vs-checkbox-box;
    height: $vs-checkbox-box;
    border-width: $vs-checkbox-radio-border-width;
    border-style: solid;
    border-color: $vs-checkbox-radio-border-color;
    border-radius: 2px;
    transform: rotate(-90deg);
    transition: all 0.2s ease;
    overflow: hidden;
    margin-top: -1px;
    // margin-right: 0.5rem;
    &.vx-checkbox-sm {
      width: $vs-checkbox-box-sm;
      height: $vs-checkbox-box-sm;
    }
    &.vx-checkbox-lg {
      width: $vs-checkbox-box-lg;
      height: $vs-checkbox-box-lg;
    }
    .vx-checkbox--check {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      left: 0;
      transform: translate(100%);
      transform-origin: right;
      transition: all 0.2s ease;
      z-index: 10;
      .vx-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
      }
    }
  }
  .vx-checkbox-label {
    line-height: 1;
  }
}

.vx-checkbox-label {
  // margin-bottom: 2px;
  cursor: pointer;
  margin-left: 0.5rem;
  font-size: 14px;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 2px;
  font-size: 13px;
  color: $primary;
}

.form-group {
  position: relative;
  margin-bottom: 40px;
  label {
    position: absolute;
    top: 6px;
    transition: all 0.2s;
    color: #a7a7a7;
    &.focused {
      top: -13px;
      transition: all 0.2s;
      color: $secondary;
      font-size: 12px;
      font-weight: bold;
    }
  }
}

.input-group {
  position: relative;
  margin-top: 10px;
}

.o-input,
.o-select {
  border-radius: 0;
  padding: 0;
  height: 38px;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 0;
  border-bottom: 2px solid $border;
  &:focus {
    border-color: $secondary;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc;
    opacity: 1; /* Firefox */
  }
}

.otp-input {
  height: 63px;
  border-radius: 5px;
  text-align: center;
  padding: 12px 7px 7px !important;
  font-size: 40px !important;
  font-weight: bold;
  width: 60px !important;
  border: 1px solid #ced4da;
  &:focus,
  &:active,
  &:focus-visible {
    border-color: $primary;
    outline: $primary;
  }
}

.n-input,
.n-select {
  border-radius: 7px !important;
  padding: 12px;
  height: 44px !important;
  box-shadow: none !important;
  // border: 1px solid $border;
  &:focus {
    border-color: $primary;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc;
    opacity: 1; /* Firefox */
  }
}

.n-select {
  border-color: $border;
  appearance: none;
  padding: 6px 18px 6px 12px;
  background-color: transparent;
  z-index: 1;
}

.n-input-addition {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  border: 1px solid #ced4da;
  background-color: #fafafa;
}

.n-input-prepend {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.n-input-append {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.stepper {
  display: flex;
  border: 1px solid #ddd;
  justify-content: space-around;
  align-items: center;
  background-color: $white;
  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 36px;
    height: 36px;
  }
  input {
    padding: 5px;
    box-shadow: none;
    outline: none;
    text-align: center;
    width: 60px;
    height: 36px;
    color: $black;
    border: 0;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
}

.o-select {
  &::placeholder,
  &:disabled,
  &[disabled],
  option[disabled],
  option:disabled {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc !important;
    opacity: 1; /* Firefox */
  }
}

.o-search-input {
  border-radius: 5px !important;
  padding: 5px;
  height: 42px;
  box-shadow: none !important;
  background-color: transparent !important;
  font-size: 14px;
  padding-left: 40px;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #bbb;
}

.infinite-scroll-component {
  overflow: unset !important;
  height: unset !important;
}

$width: 8px;

.o-select-dropdown {
  position: relative;
  margin-bottom: 20px;
  .trigger {
    background-color: $white;
    border: 1px solid #ced4da;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    max-width: 100%;
    border-radius: 5px;
    padding: 15px 22px;
    height: 44px;
    box-shadow: none;
    .trigger-text {
      // max-width: 90%;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
    }
    .trigger-arrow {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-45%);
      opacity: 0.7;
    }
  }

  ul {
    background-color: $white;
    position: absolute;
    box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.3);
    top: 120%;
    right: 0;
    z-index: 100;
    margin: 0;
    padding: 0;
    border-radius: 10px;
    transition: all 0.2s;
    min-width: 200px;
    width: 100%;
    display: block;
    max-height: 260px;
    overflow: auto;
    li {
      padding: 11px 30px 11px 15px;
      list-style: none;
      cursor: pointer;
      position: relative;
      margin: 0 auto;
      border-bottom: 1px solid #eee;
      &:first-of-type {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }
      &:last-of-type {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        border-bottom: 0;
      }
      &:hover {
        background-color: $primary10;
        border-color: $primary10;
      }
      .checked {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
      }
    }

    .no-data {
      text-align: center;
      cursor: auto;
      &:hover {
        background-color: $white;
        border-color: $white;
      }
    }
  }
}
.o-textarea {
  border-radius: 25px !important;
  padding: 15px 22px;
  box-shadow: none !important;
  &:focus {
    border-color: $secondary !important;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc;
    opacity: 1; /* Firefox */
  }
}
.o-input-prepend {
  position: absolute;
  top: 49%;
  transform: translateY(-50%);
  left: 0;
  z-index: 4;
  font-size: 0.98rem;
}
.o-input-append {
  position: absolute;
  top: 49%;
  transform: translateY(-50%);
  right: 0;
  z-index: 4;
  font-size: 0.98rem;
}

.tabs {
  display: flex;
  background-color: $white;
  padding: 15px 0 0 25px;
  box-shadow: $box-shadow;
  max-width: 100%;
  overflow-x: auto;
  .tab-list {
    padding-bottom: 10px;
    margin-right: 50px;
    cursor: pointer;
    &.active,
    &:hover {
      border-bottom: 3px solid $primary;
    }
  }
}

.category-wrapper {
  position: absolute;
  top: 116px;
  padding: 15px;
  left: 0;
  width: 100%;
  background-color: $white;
  box-shadow: $box-shadow;
  z-index: 100;
  .main-category-wrapper {
    list-style: none;
    padding-left: 0;
    max-height: 400px;
    overflow: auto;
    li {
      padding: 10px 15px;
      border-radius: 5px;
      cursor: pointer;
      &.active,
      &:hover {
        background-color: #f3f3f3;
      }
    }
  }
  .list {
    cursor: pointer;
    margin-bottom: 5px;
  }
}

.overlay {
  transition: opacity 0.15s linear;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
  &.open {
    display: block;
  }
}

$width: 30vw;

.offcanvas {
  position: fixed;
  top: 0;
  width: $width;
  height: 100vh;
  transition: all 0.2s;
  background-color: $white;
  z-index: 1051;
  &.left {
    left: -$width;
    &.open {
      left: 0;
    }
  }
  &.right {
    right: -$width;
    &.open {
      right: 0;
    }
  }
  .offcanvas-header {
    display: flex;
    padding: 12px 15px;
    justify-content: space-between;
    align-items: center;
  }
  .offcanvas-body {
    height: calc(100vh - 52px);
    overflow: auto;
  }
}

.o-input-image {
  cursor: pointer;
  margin-bottom: 15px;
  .choose {
    background-color: $primary10;
    color: $primary;
    border-radius: 10px;
    padding: 3px 7px;
    font-weight: 600;
    font-size: 12px;
    display: inline-block;
  }
}

.search-result {
  position: absolute;
  top: calc(100% + 15px);
  left: 0;
  width: 100%;
  border-radius: 5px;
  background-color: $white;
  z-index: 1055;
  padding: 15px;
  box-shadow: $box-shadow;
  border: 1px solid $border;
  .search-list {
    padding: 5px 12px;
    cursor: pointer;
    color: $black;
    font-size: 14px;
    border-radius: 5px;
    &:hover {
      background-color: #f3f3f3;
    }
  }
}

.o-overlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: -1;
  display: none;
  &.open {
    opacity: 1;
    z-index: 11;
    display: block;
  }
}

.modal-loading {
  display: none;
  .modal-loading-body {
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 35px;
    z-index: 1051;
    border-radius: 5px;
    box-shadow: $box-shadow;
    display: none;
  }
  &.open {
    display: block;
    .overlay {
      display: block;
    }
    .modal-loading-body {
      display: block;
    }
  }
}

.horizontal-scroll {
  overflow-x: auto;
  white-space: nowrap;
  .product-list {
    display: inline-block;
    width: 180px;
  }
}

.c-popover {
  background-color: $white;
  position: absolute;
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.3);
  top: 100%;
  left: 0;
  z-index: 100;
  padding: 10px 15px;
  border-radius: 10px;
  transition: all 0.2s;
  display: none;
  min-width: 200px;
  &.active {
    display: block;
  }
}
.c-overlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: -1;
  display: none;
  &.open {
    opacity: 1;
    z-index: 11;
    display: block;
  }
}

.o-loading {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1052;
  top: 0;
  left: 0;
  img {
    width: 95px;
    height: auto;
    display: block;
    margin-bottom: 40px;
    &.logo-shadow {
      box-shadow: $box-shadow;
    }
  }
  .o-loading-progress {
    background-color: #f2f2f2;
    width: 360px;
    height: 5px;
    border-radius: 5px;
    position: relative;
    .fill {
      position: absolute;
      top: 0;
      left: 0;
      background-color: $primary;
      height: 6px;
      border-radius: 5px;
      width: 0;
      animation: progress 3s forwards;
    }
  }
}

.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .step-line {
    width: 100%;
    // margin: 0 12px;
    background-color: #e2e2e2;
    height: 1px;
    top: 50%;
    &.completed {
      background-color: $primary;
    }
  }
  .step-desc {
    position: absolute;
    top: 110%;
    left: 0;
    width: 150px;
    margin-top: 5px;
  }
}

.step-bullet {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e2e2e2;
  &.current,
  &.completed {
    border-color: $primary;
    .step-bullet-in {
      background-color: $primary;
    }
  }
  .step-bullet-in {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #e2e2e2;
  }
}

.steps-vertical {
  .step-bullet {
    width: 25px;
    height: 25px;
  }
  .step-bullet-in {
    width: 11px;
    height: 11px;
  }
  .step-line {
    width: 100%;
    height: 25px;
    margin-left: 12px;
    border-left: 1px solid #e2e2e2;
    &.completed {
      border-color: $primary;
    }
  }
  .step-vertical-desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.modal-popup-home {
  .modal-content {
    background-color: transparent;
    border: 0;
  }
}

.cc-iframe {
  width: 500px;
  height: 450px;
  border: 0;
}

@keyframes progress {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .static {
    ol {
      padding-left: 15px;
    }
  }
  .cc-iframe {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  .nav-link {
    margin-top: 10px;
    &::before {
      transform: none;
      width: 40px !important;
    }
  }

  .video-container {
    padding: 4rem 15px;
    .video-wrapper {
      width: 60%;
      height: auto;
    }
  }

  .support-box {
    display: none;
  }
  .nav-grouped {
    display: block !important;
  }
}

@media (max-width: 768px) {
  @import 'mobile';
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .md-pl {
    padding-left: 20px;
  }
  .md-pr {
    padding-right: 20px;
  }

  .quiz-email {
    width: 40%;
  }
}

@media (min-width: 1024px) {
  .navbar-toggle {
    display: none;
  }
  .navbar {
    .nav-link-button {
      position: absolute;
      right: 0;
      &::before {
        width: 0;
      }
    }
  }
}

@media (min-width: 1199px) {
}
