$body-bg: #fafafa;

$primary: #eb1c2d !default;
$primary10: #fef1f2 !default;
$primary20: #fee3d0 !default;

$secondary: #005ea6 !default;

$border: #e0e0e0 !default;

$red: #f24242 !default;
$redsoft: #e76969 !default;

$black: #555 !default;
$green: #16e06a !default;
$grey: #cdcdcd !default;
$orangelight: #f8c293 !default;
$white: #fff !default;
$font-size-base: 0.875rem !default;
$input-btn-font-size: $font-size-base * 1.02;
$box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.15);
$title: 'Raleway', sans-serif;
$content: 'Arial', sans-serif;
$roboto: 'Roboto', sans-serif;
